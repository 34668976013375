import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue')
    },
    {
      path: '/settings/general',
      name: 'settings-general',
      component: () => import('@/views/settings/general.vue')
    },
    {
      path: '/settings/subjects',
      name: 'settings-subjects',
      component: () => import('@/views/settings/subjects.vue')
    },
    {
      path: '/settings/classes',
      name: 'settings-classes',
      component: () => import('@/views/settings/classes.vue')
    },
    {
      path: '/settings/teachers',
      name: 'settings-teachers',
      component: () => import('@/views/settings/teachers.vue')
    },
    {
      path: '/table',
      name: 'table',
      component: () => import('@/views/table/index.vue')
    },
    {
      path: '/tables',
      name: 'tables',
      component: () => import('@/views/table/tables.vue')
    },
    {
      path: '/t/t/:t1/:e1',
      name: 'tables2',
      component: () => import('@/views/table/public.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/t/t2/:t2',
      name: 'tables3',
      component: () => import('@/views/table/public.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/t/c/:t3/:e3',
      name: 'tables4',
      component: () => import('@/views/table/public.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/t/c4/:t4',
      name: 'tables5',
      component: () => import('@/views/table/public.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/s/:t',
      name: 'tables6',
      component: () => import('@/views/table/redirect.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/login/:t',
      name: 'login',
      component: () => import('@/views/autologin.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
